import * as THREE from 'three';
import { RGBELoader } from "three/examples/jsm/loaders/RGBELoader";
import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader'
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader"
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls"
import { TransformControls } from "three/examples/jsm/controls/TransformControls"
import { addScrollListner, getScrollValue, scalePercent } from './Utils/Utils';
import { lerp } from 'three/src/math/MathUtils';
import { getProject, types, val } from '@theatre/core';
import animationState from "./json/CameraViewer.theatre-project-state_5.json"
import studio from '@theatre/studio';
let gltfLoader = new GLTFLoader();
let dracoLoader = new DRACOLoader();
const renderer = new THREE.WebGLRenderer();
const scene = new THREE.Scene();
// Setting up camera
window.three = THREE
const clock = new THREE.Clock();
const camera = new THREE.PerspectiveCamera(
  75,
  window.innerWidth / window.innerHeight,
  0.1,
  1000
);

window.camera = camera
let video1, video2, video3, video4;
const animationScripts = [];
const project = getProject('CameraViewer', { state: animationState });
const sheet = project.sheet('CameraAnimationScene');
let mixer1 = {
  mixer: undefined,
  animations: {},
  modelReady: false
}, mixer2 = {
  mixer: undefined,
  animations: {},
  modelReady: false
};
const transformControls = new TransformControls(camera,renderer.domElement) 
// const orbit = new OrbitControls(camera, renderer.domElement);
export const Viewer = () => {
  viewerAsync()
  return <></>
}

window.scene = scene
const viewerAsync = async () => {
  // studio.initialize();

  const config = {
    rotation: types.compound({
      xR: types.number(0, { range: [-Math.PI, Math.PI] }),
      yR: types.number(0, { range: [-Math.PI, Math.PI] }),
      zR: types.number(0, { range: [-Math.PI, Math.PI] }),
    }),
    position: types.compound({
      x: types.number(0, { nudgeMultiplier: 0.1 }),
      y: types.number(0, { nudgeMultiplier: 0.1 }),
      z: types.number(0, { nudgeMultiplier: 0.1 }),
    }),
  }

  const cameraObj = sheet.object('Camera', config, { reconfigure: true });
  cameraObj.onValuesChange((values) => {
    const { xR, yR, zR } = values.rotation;
    camera.rotation.set(xR, yR, zR, THREE.Euler.DEFAULT_ORDER);
    const { x, y, z } = values.position;
    camera.position.set(x, y, z);
  });

  // Setting up renderer
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
  renderer.setClearColor(0xffffff, 0);

  // Setting up controls
  const controls = new TransformControls(camera, renderer.domElement)
  scene.add(controls)

  // Loads HDR
  await loadHDR("rostock_arches_1k.hdr", false)

  // Loads Entire Scene
  let sceneGltf = await loadGltf("finalscenev0.4.glb");
  scene.add(sceneGltf.scene);
  scene.animations.push(sceneGltf.animations);
  mixer1 = createAnimationMixer(sceneGltf);
  console.log(mixer1.animations);
  mixer1.animations["Main action"]();
  // Loads Engine  
  loadGltf("V_Shape_IC_ENGINE.glb").then((model) => {
    const engine = new THREE.Group();
    engine.name = "engine"
    engine.position.set(model.scene.position.x.toFixed(2), model.scene.position.y.toFixed(2), model.scene.position.z.toFixed(2))
    engine.rotation.set(model.scene.rotation.x.toFixed(2), model.scene.rotation.y.toFixed(2), model.scene.rotation.z.toFixed(2), THREE.Euler.DEFAULT_ORDER)
    engine.scale.set(model.scene.scale.x.toFixed(2), model.scene.scale.y.toFixed(2), model.scene.scale.z.toFixed(2))
    engine.attach(model.scene);
    engine.position.set(scene.getObjectByName("EnginePlaceHolder").position.x.toFixed(2), scene.getObjectByName("EnginePlaceHolder").position.y.toFixed(2), scene.getObjectByName("EnginePlaceHolder").position.z.toFixed(2))
    engine.scale.set(5, 5, 5);
    scene.add(engine);
    scene.animations.push(model.animations);

    const engineConfig = {
      rotation: types.compound({
        xR: types.number(0, { range: [-Math.PI, Math.PI] }),
        yR: types.number(-2.22, { range: [-Math.PI, Math.PI] }),
        zR: types.number(0, { range: [-Math.PI, Math.PI] }),
      }),
      position: types.compound({
        x: types.number(-5.55, { nudgeMultiplier: 0.1 }),
        y: types.number(3.70, { nudgeMultiplier: 0.1 }),
        z: types.number(-12.10, { nudgeMultiplier: 0.1 }),
      }),
    }
    const engineObj = sheet.object('Engine', engineConfig, { reconfigure: true });
    engineObj.onValuesChange((values) => {
      const { xR, yR, zR } = values.rotation;
      engine.rotation.set(xR, yR, zR, THREE.Euler.DEFAULT_ORDER);
      const { x, y, z } = values.position;
      engine.position.set(x, y, z);
    });
    mixer2 = createAnimationMixer(model);
  });

  // Scroll animation
  addAnimations()
  AddVideosPresentation()
  window.scrollTo({ top: 0, behavior: "smooth" });
  // playScrollAnimations()
  addScrollListner()
  animate();
}
const AddVideosPresentation = () => {
  let mesh1 = scene.getObjectByName("boardscreen2")
  window.mesh1 = mesh1  
  mesh1.add(new THREE.AxesHelper(3,3,3))
  video1 = createVideoElement("Video/SelectionSort.mp4");
  
  let videoTexture1 = createVideoTexture(video1);
  const parameter1 = { color: 0xffffff, map: videoTexture1,side:THREE.DoubleSide };

  mesh1.material = new THREE.MeshStandardMaterial(parameter1);

}
const addAnimations = () => {
  animationScripts.push(
    {
      start: 0,
      end: 60,
      func: () => {
        let scrollValue = getScrollValue();
        if (scrollValue != 0) {
          const length = val(sheet.sequence.pointer.length)
          sheet.sequence.position = lerp(0, length, scalePercent(0, 60, scrollValue))
        }
      },
    },
    {
      start: 20,
      end: 27,
      func: () => {
        let scrollValue = getScrollValue();
        if (scrollValue != 0) {
          if (mixer2.modelReady) {
            mixer2.animations["3.Assemble"]();
          }
        }
      },
    },
    {
      start: 33,
      end: 41,
      func: () => {
        let scrollValue = getScrollValue();
        if (scrollValue != 0) {
          if (mixer2.modelReady) {
            mixer2.animations["2.DissAssemble"]();
          }
        }
      },
    },
    {
      start: 42,
      end: 51,
      func: () => {
        let scrollValue = getScrollValue();
        if (scrollValue != 0) {
          if (mixer2.modelReady) {
            mixer2.animations["3.Assemble"]();
          }
        }
      },
    }
  );
}
const animate = () => {
  requestAnimationFrame(animate);
  playScrollAnimations();
  let delta = clock.getDelta();
  if (mixer1.modelReady) {
    mixer1.mixer.update(delta)
  }
  if (mixer2.modelReady) {
    mixer2.mixer.update(delta)
  }

  // orbitControls.update()
  render();
  window.camera = camera
  window.scene = scene

}
const render = () => {
  renderer.render(scene, camera);
}
const setLighting = () => {
  const light = new THREE.DirectionalLight();
  light.position.set(1, 1, 1);
  light.intensity = 2;
  scene.add(light);
  const light2 = new THREE.DirectionalLight();
  light2.position.set(1, 0, 1);
  light2.intensity = 2;
  scene.add(light2);
  const point = new THREE.PointLight(0xffffff, 0.8, 20);
  point.decay = 2;
  point.position.set(0, 7, 0);
  scene.add(point);
}

const loadHDR = async (hdrPath, isBackground = true, isEnvironment = true) => {
  let loader = new RGBELoader().setPath("HDRI/");
  let texture = await loader.loadAsync(hdrPath);
  texture.mapping = THREE.EquirectangularReflectionMapping;
  if (isBackground) {
    scene.background = texture;
  }
  if (isEnvironment) {
    scene.environment = texture;
  }
  document.getElementsByTagName('canvas')[0].classList.add('gradient1');
  render();
  return texture;
}
const loadBackground = async (backgroundPath) => {
  let loader = new THREE.TextureLoader().setPath("HDRI/");
  let texture = await loader.loadAsync(backgroundPath);
  scene.background = texture;
  render();
  return texture;
}

/**
 * Loads a GLTF model asynchronously.
 *
 * @param {string} modelName - The name of the GLTF model to load.
 * @return {Promise} A promise that resolves with the loaded GLTF model or rejects with an error.
 */
const loadGltf = async (modelName) => {
  dracoLoader.setDecoderConfig({ type: 'js' })
  dracoLoader.setDecoderPath("/draco/gltf/")
  dracoLoader.preload()

  gltfLoader = gltfLoader.setDRACOLoader(dracoLoader)

  return new Promise(async (resolve, reject) => {
    try {
      let gltf = await gltfLoader.setPath("Model/").loadAsync(modelName, (event) => {
      });
      resolve(gltf);
    } catch (error) {
      reject(error)
    }
  })
}
const playScrollAnimations = () => {
  animationScripts.forEach((a) => {
    let scrollPercent = getScrollValue();
    if (scrollPercent >= a.start && scrollPercent < a.end) {
      a.func();
    }
  });
}
const createVideoElement = (src) => {
  let video = document.createElement("VIDEO");

  if (video.canPlayType("video/mp4")) {
    video.setAttribute("src", src);
    video.setAttribute("autoplay", "true")
    video.setAttribute("width", "50");
    video.setAttribute("height", "50");
    video.muted = true
    video.loop = true;
    video.play()
    console.log(video);
    return video;
  } else {
    // x.setAttribute("src", "movie.ogg");
  }
}

const createVideoTexture = (video) => {
  let videoTexture = new THREE.VideoTexture(video);
  videoTexture.colorSpace = THREE.SRGBColorSpace;
  return videoTexture
}

const createAnimationMixer = (gltf) => {
  let mixer = new THREE.AnimationMixer(gltf.scene);
  let activeAction, lastAction;
  let animations = {};
  // the number of clips the animation have
  const totalAnimationsCount = gltf.animations.length;


  // Looping the code to map actions and names
  for (var i = 0; i < totalAnimationsCount; i++) {
    const animationAction = mixer.clipAction(gltf.animations[i]);

    // Extracting animation clip's name
    const animationClip = gltf.animations[i];
    const animationClipName = animationClip.name;

    // console.log(animationClipName)
    animations[animationClipName] = () => {
      setAction(animationAction);
    };
    activeAction = animationAction;
  }
  const setAction = (toAction) => {
    if (toAction !== activeAction) {
      lastAction = activeAction;
      activeAction = toAction;
      lastAction.fadeOut(1);
      if (activeAction.getClip().name === '2.DissAssemble' || activeAction.getClip().name === '3.Assemble') {
        activeAction.clampWhenFinished = true;
        activeAction.loop = THREE.LoopOnce;
      }
      activeAction.reset();
      activeAction.fadeIn(1);
      activeAction.play();
    }
  };
  let modelReady = true;
  return {
    mixer: mixer,
    animations: animations,
    modelReady: modelReady
  };
}