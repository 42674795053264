import React from 'react'
import styles from './HeroSection1.module.css'

function HeroSection1() {

  return (
    <section>
        <div className={`${styles.herosection1} container`}>
            <div className={`${styles.para1} container`}>
                <h2>A.I. Assistant</h2>
                <p>Create engaging and rich content with the help of generative artificial intelligence, providing top class content right in your hand.</p>
            </div>
        </div>
    </section>
  )
}

export default HeroSection1