let scrollPercent = 0;
export const getScrollValue = () => {  
  return scrollPercent;
}
export const addScrollListner = (scrollfunction = (scrollValue)=>{}) => {
  document.addEventListener('scroll', (evt) => {
    //calculate the current scroll progress as a percentage
    scrollPercent =
      ((document.documentElement.scrollTop || document.body.scrollTop) /
        ((document.documentElement.scrollHeight || document.body.scrollHeight) -
          document.documentElement.clientHeight)) *
      100;
      scrollfunction(scrollPercent);
  });
}
// Adding lerp and scroll movement
export const lerp = (x, y, a) => {
  return (1 - a) * x + a * y;
}

// Used to fit the lerps to start and end at specific scrolling percentages
export const scalePercent = (start, end,scrollPercent) => {
  return (scrollPercent - start) / (end - start);
}
window.getScrollValue = getScrollValue;