import React from 'react'
import styles from './HeroSection3.module.css'

function HeroSection3() {
  return (
    <section>
        <div className={`${styles.herosection3} container hidden`}>
        <div className={`${styles.para1} container`}>
                <h2>360 Viewer</h2>
                <p>Venture the 360 workshop.</p>
            </div>
        </div>
    </section>
  )
}

export default HeroSection3