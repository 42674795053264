import './App.css';
import HeroSection1 from './components/HeroSection1';
import HeroSection2 from './components/HeroSection2';
import NavBar from './components/NavBar';
import { Home } from './3DViewer/Home';
import { PanaromaViewer } from './PanaromaViewer/panaroma';
import HeroSection3 from './components/HeroSection3';
function App() {
  return (
    <div className="App scroller">
      <NavBar />
      <HeroSection1 />
      <HeroSection2 />
      <Home></Home>
      <HeroSection3 />
      {/* <PanaromaViewer/> */}
    </div>
  );
}

export default App;
