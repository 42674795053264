import React from 'react'
import styles from './HeroSection2.module.css'

function HeroSection2() {
  return (
    <section>
        <div className={`${styles.herosection2} container hidden`}>
        <div className={`${styles.para1} container`}>
                <h2>Augmented & Virtual Reality</h2>
                <p>Make learning more fun by visualizing tough topics in the form of 3D models in Augmented and Virtual Reality.</p>
            </div>
        </div>
    </section>
  )
}

export default HeroSection2