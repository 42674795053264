import { Viewer } from "./Viewer"
import { ScrollFix } from "./scripts/ScrollFix"

export const Home = () => {

    ScrollFix();

    return (
        <>
            
            <div id="main">
            <Viewer />
            </div>
        </>
    )
}
