import React from 'react'
import styles from './NavBar.module.css'

function NavBar() {
  return (
    <section>
      <nav className={`${styles.navigation}`}>

          <img src="/Images/3d-model.png" width={45} height={45} alt="logo" />

        {/* <ul>
          <li>Home</li>
          <li>About</li>
          <li>Services</li>
          <li>Contact</li>
        </ul> */}
      </nav>

      <div className={`${styles.heroSection} fixed`}>
        <div className={`${styles.para1} container`}>
          <h1>Witness The Revolution in Teaching</h1>
          <span>By leveraging the latest tech</span>
        </div>
      </div>
    </section>
  )
}

export default NavBar
