import "@photo-sphere-viewer/core/index.css";
import { Viewer } from '@photo-sphere-viewer/core';

import { useEffect } from 'react';
import { MarkersPlugin } from '@photo-sphere-viewer/markers-plugin';
import { addScrollListner, scalePercent } from "../3DViewer/Utils/Utils";
import { lerp } from "three/src/math/MathUtils";

export const PanaromaViewer = (element) => {
        const viewerHtml =  document.createElement("div");
        viewerHtml.setAttribute("id", "viewer");
        viewerHtml.setAttribute("position", "absolute");
        viewerHtml.setAttribute('style', 'position: absolute; z-index: -1');
        // document.getElementById('viewer').classList.add('setindex');
        element.appendChild(viewerHtml);
        const viewer = new Viewer({
            container: viewerHtml,
            panorama: 'HighQualityWorkshop.png',
            plugins: [
                [MarkersPlugin],
            ],
            mousewheel: false,
        });
        const extendedPosition = { yaw: 0, pitch: 0 }
        const addRotation = (scrollValue) => {

            extendedPosition.yaw = lerp(0, 360, scalePercent(60, 100, scrollValue) * .01);
            viewer.rotate(extendedPosition);
        }
        addScrollListner(addRotation)
        const markersPlugin = viewer.getPlugin(MarkersPlugin);
        markersPlugin.addMarker({
            id: '#' + Math.random(),
            // imageLayer: baseUrl + 'pictos/tent.png',
            imageLayer: 'pin-red.png',
            size: { width: 32, height: 32 },
            position: {
                yaw: 0.94,
                pitch: -0.07
            },
            tooltip: 'Engineering Workshop',
        });
        viewer.container.getElementsByClassName("psv-navbar")[0].setAttribute("style", "display: none;");
        // viewer.addEventListener('click', ({ data }) => {
        //     markersPlugin.addMarker({
        //         id: '#' + Math.random(),
        //         // imageLayer: baseUrl + 'pictos/tent.png',
        //         imageLayer: 'pin-red.png',
        //         size: { width: 32, height: 32 },
        //         position: { yaw: data.yaw, pitch: data.pitch },
        //         tooltip: 'Image embedded in the scene',
        //     });
        //     console.log({ yaw: data.yaw, pitch: data.pitch });
        // })
    
}