import { addScrollListner } from "../Utils/Utils"
import './ScrollFix.module.css'
import { PanaromaViewer } from '../../PanaromaViewer/panaroma'


const areaOne = (scrollvalue) => {
    // making panel0 fixed for scrollvalue
    document.getElementsByClassName('NavBar_heroSection__SnHiR')[0].classList.add('fixed');

    // making panel0 visible 
    document.getElementsByClassName('NavBar_heroSection__SnHiR')[0].classList.remove('hidden');

    // making panel1 relative and hidden
    document.getElementsByClassName('HeroSection1_herosection1__7O6eX')[0].classList.add('hidden');

    // Managing gradient
    document.getElementsByTagName('canvas')[0].classList.add('gradient1');
    document.getElementsByTagName('canvas')[0].classList.remove('gradient2');

    // // panel0 fadeout effect
    // document.getElementsByClassName('NavBar_heroSection__SnHiR')[0].classList.add('fadeOutAnimation');
}

const areaTwo = (scrollvalue) => {
    // making panel0 hidden and removing fixed
    document.getElementsByClassName('NavBar_heroSection__SnHiR')[0].classList.add('hidden');
    document.getElementsByClassName('NavBar_heroSection__SnHiR')[0].classList.remove('fixed');

    // making panel1 visible for scrollvalue
    document.getElementsByClassName("HeroSection1_herosection1__7O6eX")[0].classList.add('visible');
    document.getElementsByClassName('HeroSection1_herosection1__7O6eX')[0].classList.remove('hidden');
    

    // making panel1 fixed for scrollvalue
    document.getElementsByClassName('HeroSection1_herosection1__7O6eX')[0].classList.add('fixed');

    // making panel2 hidden and relative
    document.getElementsByClassName('HeroSection2_herosection2__DKn4e')[0].classList.add('hidden');

    // Changing gradient
    document.getElementsByTagName('canvas')[0].classList.remove('gradient1');
    document.getElementsByTagName('canvas')[0].classList.add('gradient2');
    document.getElementsByTagName('canvas')[0].classList.remove('gradient3');
}

const areaThree = (scrollvalue) => {

    if (scrollvalue < 50){
    // making panel2 visible
        document.getElementsByClassName("HeroSection2_herosection2__DKn4e")[0].classList.add('visible');
        document.getElementsByClassName('HeroSection2_herosection2__DKn4e')[0].classList.remove('hidden');
    }
    else if (scrollvalue > 50)
    {
        document.getElementsByClassName("HeroSection2_herosection2__DKn4e")[0].classList.add('hidden');
        document.getElementsByClassName('HeroSection2_herosection2__DKn4e')[0].classList.remove('visible');
    }
    // making panel2 fixed 
    document.getElementsByClassName("HeroSection2_herosection2__DKn4e")[0].classList.add('fixed');
    
    // making panel1 relative and hidden
    document.getElementsByClassName('HeroSection1_herosection1__7O6eX')[0].classList.add('hidden');
    
    // making canvas and last components visible
    document.getElementsByClassName('App')[0].classList.add('visible');
    document.getElementsByTagName('canvas')[0].classList.add('visible');
    document.getElementsByClassName('App')[0].classList.remove('hidden');
    document.getElementsByTagName('canvas')[0].classList.remove('hidden');

    // Changing gradient
    document.getElementsByTagName('canvas')[0].classList.remove('gradient2');
    document.getElementsByTagName('canvas')[0].classList.add('gradient3');

    const viewer1 = document.getElementById('viewer');
    if (viewer1){
    console.log('==>' + viewer1)
    document.body.removeChild(viewer1);
    }

}

const areaFour = (scrollvalue) => {
    // hiding canvas and last components
    // document.getElementsByClassName('App')[0].classList.add('hidden');
    document.getElementsByTagName('canvas')[0].classList.add('hidden');

    if (scrollvalue < 68) {
        document.getElementsByClassName('HeroSection3_herosection3__CtkV7')[0].classList.add('hidden');
    }
    else if (scrollvalue > 68 && scrollvalue < 76.5) {
        // // making panel1 visible for scrollvalue
        document.getElementsByClassName('HeroSection3_herosection3__CtkV7')[0].classList.remove('hidden');
        document.getElementsByClassName('HeroSection3_herosection3__CtkV7')[0].classList.add('visible');
        document.getElementsByClassName('HeroSection3_herosection3__CtkV7')[0].classList.add('fixed');
    }
    else if (scrollvalue > 76.5)
    {
        document.getElementsByClassName('HeroSection3_herosection3__CtkV7')[0].classList.add('hidden');
        document.getElementsByClassName('HeroSection3_herosection3__CtkV7')[0].classList.remove('fixed');
    }

    // insert panaroma
    if (!document.getElementById("viewer")) {
        PanaromaViewer(document.body);
    }
    
}


export const ScrollFix= ()=>{
    const scrollFunc = (scrollvalue)=>{

        if (scrollvalue < 20)
        {
            areaOne(scrollvalue);
        }

        else if (scrollvalue > 20 && scrollvalue < 30) 
        {
            areaTwo(scrollvalue);
        }

        else if (scrollvalue > 30 && scrollvalue < 60)
        {
            areaThree(scrollvalue);
        }
        else if (scrollvalue > 60)
        {
            areaFour(scrollvalue);
        }
    }
    addScrollListner(scrollFunc)
}